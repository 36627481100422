/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

	      var body_has_class = false;

	      jQuery('.navbar-toggle').on( 'click', function() {
		    if( !body_has_class ) {
			    jQuery('body').addClass( 'nav-open' );
			    body_has_class = true;
		    }
		    else {
			    jQuery('body').removeClass( 'nav-open' );
			    body_has_class = false;
		    }


	      });
	    
	    var scrollPos = 0;
		var scrollElement = 'body';
		var isMobile = false;
		if(navigator.appVersion.indexOf("MSIE 9.")!== -1) {
			isMobile = false;
		} else {
            isMobile = window.matchMedia("only screen and (max-width: 760px)");
		}
	      
		//Removes the collapse-panels for large screens on resize or when loaded
		jQuery(document).ready(function(){
			if(jQuery(window).width() > 766){
				jQuery('.location').removeClass('collapse');
				
		    }else {
			    jQuery('.menu-item a').attr("data-disabled", "true");
			    jQuery('.location').addClass('collapse');
		    }

			jQuery('nav li a').on('touchstart mouseenter focus', function(e) {
			    if(e.type === 'touchstart') {
			        // Don't trigger mouseenter event if they hold
			        e.stopImmediatePropagation();
			    }
			});

		});    
	    jQuery(window).resize(function(){
		    if(jQuery(window).width() > 766){
				jQuery('.location').removeClass('collapse');
		    }else {
			    jQuery('.location').addClass('collapse');
		    }
	    });


		  jQuery.fn.isOnScreen = function(){
			    var viewport = {};
			    viewport.top = jQuery(window).scrollTop();
			    viewport.bottom = viewport.top + jQuery(window).height();
			    var bounds = {};
			    bounds.top = this.offset().top;
			    bounds.bottom = bounds.top + this.height();
			    return ((bounds.top <= viewport.bottom) && (bounds.bottom >= viewport.top));
			};


			checkBurgerColor = function(scrollPos) {
				burgerClass = 'petrol';
				jQuery('section.petrol, section.weiss').each(function(){
					if ((jQuery(this).offset().top - scrollPos) < 50 && jQuery(this).isOnScreen()) {
						if (jQuery(this).hasClass('weiss')) {
							burgerClass = 'white';
						}
					}
				});
				jQuery('.hamburger-menu, header').removeClass('petrol');
				jQuery('.hamburger-menu, header').removeClass('white');
				jQuery('.hamburger-menu, header').addClass(burgerClass);

			};

			//** Burger-Menu stuff **//
			checkBurgerColor(jQuery(window).scrollTop());
			jQuery(window).scroll(function(){
				checkBurgerColor(jQuery(window).scrollTop());
			});


	      	jQuery('select').selectpicker({
			  style: 'btn-default'
			});


	      	jQuery('.panel, .location').on('show.bs.collapse', function () {
		      jQuery(this).parent().find('.expander').addClass('open');
			});

			jQuery('.panel, .location').on('hide.bs.collapse', function () {
		      jQuery(this).parent().find('.expander').removeClass('open');
			});

			jQuery(document).bind('gform_post_render', function(){
				jQuery('select').selectpicker({
					style: 'btn-default',
					size: 4
				});

		    });

		    jQuery('a[href^="#"]:not([data-toggle="tab"], [data-toggle])').click(function( e ) {

			    var ele = jQuery(jQuery(this).attr('href'));

			    if( ele.length > 0 ) {
				     e.preventDefault();
				    jQuery('html, body').animate({
				        scrollTop: ele.offset().top
				    }, 400);
			    }

			});

			jQuery('a.btn-reset').on('click', function(event) {
				event.preventDefault();
				jQuery('select').prop('selectedIndex',-1);
				jQuery('select').selectpicker('render');
			});

			jQuery('.slide').slick({responsive: [
				{
			      breakpoint: 992,
			      settings: {
			        slidesToShow: 3,
			        slidesToScroll: 1,
			        infinite: true
			      }
			    },
				{
			      breakpoint: 768,
			      settings: {
			        slidesToShow: 2,
			        slidesToScroll: 1,
			        infinite: true
			      }
			    },
			    {
			      breakpoint: 480,
			      settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1,
					infinite: true
			      }
			  	}
			]});
			
			// modal - infotext and organigramm
			jQuery('.btn-infotext, .greybox-close, .organigramm-open-model').on('click', function(e){
								
				e.preventDefault();
				
				// get organigramm to modify
				var target = jQuery(this).data('target');
				
				// open and close
				if( jQuery(this).hasClass('btn-infotext') || jQuery(this).hasClass('organigramm-open-model') ) {
					jQuery(target + ', .infobox-container').addClass('in');					
				} else {
					jQuery(target + ', .infobox-container').removeClass('in');				
				}
				
				// only organigramm
				if( jQuery(this).hasClass('organigramm-open-model') ) {
					var minHeight = jQuery('.organigramm').height();
					var modalHeight = jQuery(target).outerHeight();
					
					// check minheight (organigramm height) seen as in design
					if( minHeight > modalHeight ) {
						modalHeight = minHeight;
					}
					
					// set height for open organigram, because of absolute position
					jQuery(target + ', .organigramm-modal-container').height(modalHeight);
				}
				
			});
			
			// modal resize height on window resize - organigramm
			jQuery(window).on('resize', function(){
				
				if( jQuery('.organigramm-modal.in').length > 0 ) {
					
					var minHeight = jQuery('.organigramm').height();
					var modalHeight = jQuery('.organigramm-modal.in').outerHeight();
					
					// check minheight (organigramm height) seen as in design
					if( minHeight > modalHeight ) {
						modalHeight = minHeight;
					}
					
					// set height for open organigram, because of absolute position
					jQuery('.organigramm-modal.in, .organigramm-modal-container').height(modalHeight);
					
				}
				
			});
			
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  	// Youtube video loader
	jQuery('body').on( 'click', '.video', function(event) {
		event.preventDefault();
		if( ! jQuery(this).hasClass('video-loaded') ) {
			var video = jQuery(this).data('video_id');
			jQuery(this).data('html', jQuery(this).html());
			jQuery(this).data('background', jQuery(this).css('background-image'));
			jQuery(this).html('<iframe src="//www.youtube.com/embed/' + video + '?autoplay=1&autohide=1&color=white&loop=1&playsinline=1&rel=0&showinfo=0" frameborder="0" allowfullscreen></iframe>');
			jQuery(this).css('background-image', 'none');
			jQuery(this).addClass('video-loaded');
		}
	});

	// scroll out search
	jQuery('.btn-search').on('click', function() {
	    jQuery(this).parent().find('.team-search').toggleClass('team-search-open');
	});

	// Plus information team overview
	jQuery('body').on('click', 'figcaption', function() {
		if(jQuery('.team-plus').is(':visible')) {
			jQuery(this).find('.team-plus').toggleClass('open');
			jQuery(this).find('.team-meta-info').slideToggle();
		}
	});


})(jQuery); // Fully reference jQuery after this point.


// Scroll to next section
function scrollNext( el ) {
	var scroll_to_section = jQuery(el).parents('section').next('section');

	var offset = scroll_to_section.offset().top;

	jQuery('html, body').animate({
        scrollTop: offset
    }, 500);
}
